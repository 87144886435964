var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app__container", attrs: { id: "app" } },
    [
      _c("TheHeader"),
      _c(
        "transition",
        {
          attrs: { name: "app__container-", mode: "out-in", type: "animation" }
        },
        [_c("router-view")],
        1
      ),
      _c("TheFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }