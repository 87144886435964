var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "movie-featured" }, [
    _c(
      "p",
      { staticClass: "movie-featured__poster" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "a",
              to: {
                name: "MovieDetailPage",
                params: {
                  id: _vm.movie.id,
                  slug: _vm.$options.filters.slugify(_vm.movie.title)
                }
              }
            }
          },
          [
            _c("img", {
              staticClass: "movie-featured__image",
              attrs: {
                src: "/img/movies/" + _vm.movie.poster,
                alt: _vm.movie.title
              }
            })
          ]
        )
      ],
      1
    ),
    _c(
      "h4",
      { staticClass: "movie-featured__title" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "a",
              to: {
                name: "MovieDetailPage",
                params: {
                  id: _vm.movie.id,
                  slug: _vm.$options.filters.slugify(_vm.movie.title)
                }
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.movie.title) + " "),
            _c("small", [_vm._v("(" + _vm._s(_vm.movie.year) + ")")])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }