var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("p", { staticClass: "footer__body" }, [
        _vm._v("By Dennis Burger, october 2019")
      ]),
      _c("p", { staticClass: "footer__body" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/dutchwebworks/vue-learning",
              target: "_blank"
            }
          },
          [_vm._v("View on Github")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }