var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-confirm--fade" } }, [
    _vm.showModalConfirm
      ? _c("div", { staticClass: "modal-confirm" }, [
          _c(
            "div",
            {
              staticClass: "modal-confirm__block",
              attrs: {
                role: "dialog",
                "aria-labelledby": "modalConfirmTitle",
                "aria-describedby": _vm.options.description
                  ? "modalConfirmDescription"
                  : false
              }
            },
            [
              _c("div", { staticClass: "modal-confirm__header" }, [
                _c(
                  "h2",
                  {
                    staticClass: "modal-confirm__title",
                    attrs: { id: "modalConfirmTitle" }
                  },
                  [_vm._v(" " + _vm._s(_vm.options.title) + " ")]
                )
              ]),
              _vm.options.description
                ? _c(
                    "div",
                    {
                      staticClass: "modal-confirm__body",
                      attrs: { id: "modalConfirmDescription" }
                    },
                    [_vm._v(" " + _vm._s(_vm.options.description) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "modal-confirm__footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "modal-confirm__button button button--03",
                    attrs: { "aria-label": "Cancel modal" },
                    on: { click: _vm.cancel }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.options.cancelButtonLabel || "Cancel") +
                        " "
                    )
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "modal-confirm__button button button--02",
                    attrs: { "aria-label": "Confirm modal" },
                    on: { click: _vm.confirm }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.options.okButtonLabel || "Ok") + " "
                    )
                  ]
                )
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }