var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "overview" },
    [
      _c(
        "nav",
        { staticClass: "overview__nav" },
        [
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "NavButtonsPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Nav. buttons ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "VuexCounterPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Vuex counter ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "BuyCardsPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Buy cards ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "TabsPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Tabs ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "CustomDirectivesPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Custom Directives ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "VuelidatePage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Vuelidate ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "ArraySortingPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Array sorting ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "Faq" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" FAQ ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "MoviesPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Movies ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "FormModulesPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Form modules ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "ModalPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Modal ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "TvShowsPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Tv Shows ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "GsapPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" GSAP Animation ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "overview__nav-item",
              attrs: {
                tag: "div",
                to: { name: "MethodPropPage" },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" Method Prop ")]
          )
        ],
        1
      ),
      _vm._m(0),
      _c("hr"),
      _c("h3", { staticClass: "heading" }, [
        _vm._v("Featured movie of the minute")
      ]),
      _c("MovieFeatured", { attrs: { movie: _vm.featuredMovie } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "overview__logo" }, [
      _c("img", {
        staticClass: "overview__logo-img",
        attrs: { src: require("@/assets/img/vuejs-logo.png"), alt: "Vue logo" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }