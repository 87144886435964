var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("h1", { staticClass: "header__title" }, [
      _vm._v("SPA learning with Vue, Vuex and vue-router")
    ]),
    _c(
      "nav",
      { staticClass: "header__nav" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "a",
              to: { name: "IndexPage" },
              "active-class": "is-active",
              "exact-active-class": "is-exact",
              exact: ""
            }
          },
          [_vm._v(" Overview ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }